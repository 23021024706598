<template>
  <VSkeletonLoader
    grid-list-lg
    fluid
    type="table-heading,card"
    :loading="isLoading"
  >
    <VRow id="template-details">
      <VCol
        cols="12"
        class="text-center"
      >
        <h1 class="main-title">
          Use Our {{ item.template.title }} Program Template
        </h1>
        <h2 
          class="main-subtitle-alt"
          style="padding: 0px 168px;"
        >
          Great news: this is our bread & butter. We have a quick-start template for {{ item.template.title }} Programs to help you get up and running ASAP. 
        </h2>
      </VCol>
      <VCol 
        cols="12"
        class="text-center"
      >
        <div class="font-weight-black title">
          Here’s What You’ll Get
        </div>
      </VCol>
      <VCol 
        cols="12"
        :style="{
          padding: templateItems.length > 4 ? 'auto' : '0px 18%',
        }"
      >
        <VRow justify="center">
          <VCol
            v-for="(templateItem, index) in templateItems"
            class="py-4"
            :cols="templateItems.length > 4 ? 4 : 6"
            :key="index"
          >
            <VCard
              height="100%"
              min-height="188px"
              style="box-shadow: 0px 5px 25px 10px rgba(0, 0, 0, 0.02);"
            >
              <VCardTitle class="border-none justify-center">
                <VCol class="text-center">
                  <VAvatar
                    size="90"
                    style="top: -24px"
                    :color="templateItem.backgroundColor"
                  >
                    <img 
                      :src="templateItem.icon"
                      :style="{
                        height: templateItem.height,
                        width: templateItem.width,
                        objectFit: 'contain',
                      }"
                    >
                  </VAvatar>
                </VCol>
              </VCardTitle>
              <VCardText class="text-center">
                <VRow>
                  <VCol 
                    cols="12"
                    class="title font-weight-bold py-0"
                  >
                    {{ templateItem.title }}
                  </VCol>
                  <VCol 
                    cols="12"
                    class="description pb-0"
                    :ref="`${templateItem.name}-description`"
                  >
                    {{ getDescription(templateItem.items) }}
                  </VCol>
                  <VCol 
                    v-if="templateItem.hasMore"
                    cols="12"
                  >
                    <VTooltip
                      right
                      max-width="340px"
                    >
                      <template #activator="{ on }">
                        <VChip 
                          v-on="on"
                          color="#ECECF1" 
                          text-color="#000000"
                          style="font-size: 18px;"
                        >
                          View More
                        </VChip>
                      </template>
                      <ol>
                        <li
                          v-for="(item, key) in templateItem.items"
                          :key="key"
                        >
                          {{ item.title }}
                        </li>
                      </ol>
                    </VTooltip>
                  </VCol>
                </VRow>
              </VCardText>
            </VCard>
          </VCol>
        </VRow>
      </VCol>
    </VRow>
  </VSkeletonLoader>
</template>
<script>
import { mapActions } from "vuex";
import CreateProgramMixin from "@/mixins/CreateProgram";
export default {
  name: "ProgramsTemplateDetails",
  mixins: [CreateProgramMixin],
  data() {
    return {
      templateItems: [],
      isLoading: false,
    };
  },
  computed: {
    templateFeatures() {
      return [
        {
          key: "pipelines",
          name: "pipelines",
          title: this.featureName("Pipelines"),
          icon: require("../../../images/application-white-icon@3x.png"),
          backgroundColor: "#93D7A3",
          height: "55px",
          width: "100%",
          hasMore: false,
          items: [],
        },
        {
          key: "lessons",
          name: "lessons",
          title: this.featureName("Lessons"),
          icon: require("../../../images/lessons-white-icon.png"),
          backgroundColor: "#98C1FF",
          height: "48px",
          width: "100%",
          hasMore: false,
          items: [],
        },
        {
          key: "primary_contents",
          name: "content",
          title: this.featureName("Content"),
          icon: require("../../../images/content-white-icon.png"),
          backgroundColor: "#F2A3CA",
          height: "55px",
          width: "100%",
          hasMore: false,
          items: [],
        },
        {
          key: "deliverables",
          name: "deliverables",
          title: this.featureName("Deliverables"),
          icon: require("../../../images/forms-white-icon.png"),
          backgroundColor: "#F9D470",
          height: "74px",
          width: "60px",
          hasMore: false,
          items: [],
        },
        {
          key: "faqs",
          name: "faqs",
          title: this.featureName("FAQs"),
          icon: require("../../../images/faq-white-icon.png"),
          backgroundColor: "#D6A4DB",
          height: "55px",
          width: "100%",
          hasMore: false,
          items: [],
        },
      ];
    },
  },
  watch: {
    activeTab: {
      handler: function(value) {
        if(value === 4) {
          this.onGet(this.item.template.id);
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      doGetTemplate: "template/getOne",
    }),
    onGet(id) {
      this.isLoading = true;
      this.$bus.$emit("templateLoading", true);
      this.doGetTemplate({
        id,
        type: "program",
      }).then((result) => {
        this.templateItems = this.templateFeatures.map((feature) => {
          feature.items = result.data[feature.key];
          return feature;
        }).filter((feature) => feature.items.length > 0);
        this.isLoading = false;
        this.$bus.$emit("templateLoading", false);
        this.$nextTick(() => {
          this.templateItems = this.templateItems.map((item) => {
            item.hasMore = this.hasMoreDescription(item);
            return item;
          });
        });
      });
    },
    getDescription(items) {
      return items.map((item) => item.title).join(", ");
    },
    hasMoreDescription(item) {
      if(this.$refs.hasOwnProperty(`${item.name}-description`)) {
        const element = this.$refs[`${item.name}-description`][0];
        return element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth;
      }
      return false;
    },
  },
}
</script>
<style lang="scss" scoped>
.description {
  font-size: 20px; 
  line-height: 23.44px;     
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>